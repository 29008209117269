import React from 'react'

export default function HCPWhoCanTakePart({ lang, whocantakepart }) {
    
    //  get our grid row and column
    const getClassName = (index) => {
        const   rowIndex = index < 4 ? 1 : 2,
                colIndex = rowIndex == 1 ? index + 1 : index - 3
        
        return 'r' + rowIndex + 'c' + colIndex
    }
    
    return (
        <div id={whocantakepart.anchor} className='m-w-c-t-p nav-section'>
            <img className='dna-icon' src={whocantakepart.imgA.src} alt={whocantakepart.imgA[lang]}/>
            <div className='inner'>
                <h2 dangerouslySetInnerHTML={{__html: whocantakepart.hA[lang]}}/>
                <ul>
                {
                    whocantakepart.listA.map((item, index)=>(
                    <li key={index} className={getClassName(index)}>
                        <img 
                        src={item.img.src}
                        alt={item.img[lang]}
                        key={index + 'img'}
                        />
                        <p dangerouslySetInnerHTML={{__html: item[lang]}}/>
                    </li>
                    ))
                }
                </ul>
                <div className="dna-hr">
                    <img src={whocantakepart.imgB.src} alt={whocantakepart.imgB[lang]}/>
                    <span></span>
                </div>
                <p dangerouslySetInnerHTML={{__html: whocantakepart.pA[lang]}}/>
            </div>
        </div>
    )
}