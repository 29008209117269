import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

export default function HCPWhatIs({ lang, whatis }) {

    return (
        <div id={whatis.anchor} className='m-w-i nav-section'>
            <img className='dna-icon' src={whatis.imgA.src} alt={whatis.imgA[lang]}/>
            <div className='inner'>
                <h2 dangerouslySetInnerHTML={{__html: whatis.hA[lang]}}/>
                <p dangerouslySetInnerHTML={{__html: whatis.pA[lang]}}/>
                <span className='card-footer'>
                    <StaticImage
                    src='../../raster/dna-strand-2-icon.png'
                    alt='DNA strand icon #2'
                    placeholder='none'
                    />
                </span>
            </div>
        </div>
    )
}