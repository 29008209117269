import React from 'react'
import LocationsAccordian from './LocationsAccordian'
import { StaticImage } from 'gatsby-plugin-image'

export default function HCPLocations({ lang, locations, locationdata, sitecore }) {
    return (
        <div id={locations.anchor} className='m-l nav-section'>
            <img className='dna-icon' src={locations.imgA.src} alt={locations.imgA[lang]}/>
            <div className='inner'>
                <div className='inner-content'>
                    <h2 dangerouslySetInnerHTML={{__html: locations.hA[lang]}}/>
                    <p dangerouslySetInnerHTML={{__html: locations.pA[lang]}}/>
                </div>
                <LocationsAccordian
                    lang={lang}
                    locationdata={locationdata}
                    sitecore={sitecore}
                    />
                <div className='inner-content'>
                    <h2 dangerouslySetInnerHTML={{__html: locations.hB[lang]}}/>
                    <div className='row flag-patient'>
                        <span><img src={locations.imgB.src} alt={locations.imgB[lang]}/></span>
                        <p dangerouslySetInnerHTML={{__html: locations.pB[lang]}}/>
                    </div>
                    <div className='row flag-investigator'>
                        <span><img src={locations.imgC.src} alt={locations.imgC[lang]}/></span>
                        <p dangerouslySetInnerHTML={{__html: locations.pC[lang]}}/>
                    </div>
                </div>
                <span className='card-footer'>
                    <StaticImage
                    src='../../raster/world-map-graphic.png'
                    alt='World map graphic'
                    placeholder='none'
                    />
                </span>
            </div>
        </div>
  )
}