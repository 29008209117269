import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

export default function HCPRopeginterferon({ lang, rope }) {
    return (
        <div id={rope.anchor} className='m-r nav-section'>
            <img className='dna-icon' src={rope.imgA.src} alt={rope.imgA[lang]}/>
            <div className='inner'>
                <h2 dangerouslySetInnerHTML={{__html: rope.hA[lang]}}/>
                <StaticImage
                    src='../../raster/ropeginterferon-alfa-2b-njft-moa-graphic.jpg'
                    alt='Ropeginterferon alfa-2b-njft MOA graphic'
                    placeholder='none'
                    />
                <p dangerouslySetInnerHTML={{__html: rope.pA[lang]}}/>
            </div>
        </div>
    )
}