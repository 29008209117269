import React from 'react'

export default function HCPStudy({ lang, studydetails }) {
    const study = studydetails.study
    return (
        <div id={studydetails.anchor} className='m-s-d nav-section'>
            <img className='dna-icon' src={studydetails.imgA.src} alt={studydetails.imgA[lang]}/>
            <div className='inner'>
                <div className="grp">
                    <h1 dangerouslySetInnerHTML={{__html: studydetails.hA[lang]}}/>
                    <p dangerouslySetInnerHTML={{__html: studydetails.pA[lang]}}/>

                    <div id="hcp-study-design">
                        <h3 dangerouslySetInnerHTML={{__html: study.title}}/>
                        <div className="inner">
                            <div className='intro'>
                                <ul>
                                    {
                                        study.intro.map((item, index)=>(
                                            <li 
                                            key={index}
                                            dangerouslySetInnerHTML={{__html: item}}
                                            />
                                        ))
                                    }
                                </ul>
                            </div>
                            <span className='triangle'></span>
                            <div className='row'>
                                <p className='label' dangerouslySetInnerHTML={{__html: study.rowA.label}}/>
                                <p className='study-period' dangerouslySetInnerHTML={{__html: study.rowA.period}}/>
                                <span className='row-border'></span>
                            </div>
                            <span className='triangle'></span>
                            <div className='row'>
                                <div className='split'>
                                    {
                                        study.rowB.splitA.map((item, index)=>(
                                            <p className='label' key={index} dangerouslySetInnerHTML={{__html: item}}/>
                                        ))
                                    }
                                </div>
                                <div className='rope'>
                                    <div className='split'>
                                        {
                                            study.rowB.splitB.map((item, index)=>(
                                                <span className='vertical-text' key={index + "span"}><p className='label' key={index} dangerouslySetInnerHTML={{__html: item}}/></span>
                                            ))
                                        }
                                    </div>
                                    <h3 dangerouslySetInnerHTML={{__html: study.rowB.rope}}/>
                                </div>
                                <div className='dose'>
                                    <div className='col'>
                                        <span className='triangle'></span>
                                        <span className='line'></span>
                                        <span className='triangle'></span>
                                    </div>
                                    <p dangerouslySetInnerHTML={{__html: study.rowB.dose}}/>
                                </div>
                                <span className='row-border'></span>
                            </div>
                            <span className='triangle'></span>
                            <div className='row'>
                                <p className='label' dangerouslySetInnerHTML={{__html: study.rowC.label}}/>
                                <p className='study-period' dangerouslySetInnerHTML={{__html: study.rowC.period}}/>
                            </div>
                        </div>
                    </div>
                    <div className='annotation'>
                        <div className='col subtext'>
                            <div className='row'>
                                <p>*</p>
                                <p className='subtext' dangerouslySetInnerHTML={{__html: studydetails.pB[lang]}}/>
                            </div>
                        </div>
                    </div>
                    
                    <div className="dna-hr">
                        <img src={studydetails.imgB.src} alt={studydetails.imgB[lang]}/>
                        <span></span>
                    </div>
                </div>
                <div>
                    <p className='text-color-primary bold' dangerouslySetInnerHTML={{__html: studydetails.pC[lang]}}/> 
                    <ul>
                    {
                        studydetails.listA.map((item, index)=>(
                        <li key={index} dangerouslySetInnerHTML={{ __html: item[lang] }} />
                        ))
                    }
                    </ul>
                </div>
                <div>
                    <p className='text-color-primary bold' dangerouslySetInnerHTML={{__html: studydetails.pD[lang]}}/> 
                    <ul>
                    {
                        studydetails.listB.map((item, index)=>(
                        <li key={index} dangerouslySetInnerHTML={{ __html: item[lang] }} />
                        ))
                    }
                    </ul>
                </div>
                <div>
                    <p className='text-color-primary bold' dangerouslySetInnerHTML={{__html: studydetails.pE[lang]}}/> 
                    <ul>
                    {
                        studydetails.listC.map((item, index)=>(
                        <li key={index} dangerouslySetInnerHTML={{ __html: item[lang] }} />
                        ))
                    }
                    </ul>
                </div>
            </div>
        </div>
    )
}