import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

export default function HCPHero({ lang, hero }) {

    return (
        <div id='hero'>
            <img className='dna-icon' src={hero.imgA.src} alt={hero.imgA[lang]}/>
            <div className='inner'>
                <div className='row'>
                    <img className='logo' src={hero.imgB.src} alt={hero.imgB[lang]}/>
                </div>
                <p dangerouslySetInnerHTML={{__html: hero.pA[lang]}}/>
                <p dangerouslySetInnerHTML={{__html: hero.pB[lang]}}/>
                <p dangerouslySetInnerHTML={{__html: hero.pC[lang]}}/>
                <p dangerouslySetInnerHTML={{__html: hero.pD[lang]}}/> 
                <span className='card-footer'>
                    <StaticImage
                    src='../../raster/dna-structure.png'
                    alt='DNA strand graphic'
                    placeholder='none'
                    />
                </span>
            </div>
        </div>
    )
}